@import '@did/uikit/src/variables';

.accountInput {
  position: relative;
  display: flex;
  flex-direction: column;
}

.accountInputInput {
  height: 34px;
  box-sizing: content-box;
  padding: 10px 24px 10px 24px;
  border-radius: 12px;
  border: 1px solid $normal-color;
  background: $normal-color;
  color: $primary-font-color;
  caret-color: #22C493;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  -webkit-appearance: none;
  appearance: none;

  &:focus{
    border-color: #22C493;
    background: $white;
  }


  &:hover {
    border: 1px solid #22C493;
    background: $white;
  }

  &::placeholder {
    color: #808191;
    font-weight: 400;
  }
}

.accountInputInputIsAccount {
  padding: 11px 48px 11px 24px;
}

.accountInputInputAccount {
  padding: 11px 108px 11px 24px;
}

.accountInputInputError {
  border: 1px solid $error-color;
  background: $white;

  &:hover {
    border: 1px solid $error-color;
  }
}

.accountInputErrorMessages {
  margin-top: 4px;
  height: 14px;
  font-size: 12px;
  font-weight: 600;
  color: $error-font-color;
  line-height: 14px;
  text-align: left;
}

.accountInputAccount {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 16px;
  height: 58px;
}

.accountInputAccountContainerToAddress {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 600;
  background: rgba(35, 38, 75, 0.06);
  border-radius: 16px;
  overflow: hidden;
  color: $primary-font-color;
}

.accountInputAccountContainer {
  display: flex;
  align-items: center;
  padding: 1px;
  background: $white;
  border-radius: 16px;
  background: rgba(35, 38, 75, 0.06);
}

.accountInputAccountToAddress {
  margin-right: 6px;
}

.accountInputParsingRecords {
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  padding: 8px 0 12px 0;
  box-shadow: 0 22px 14px 0 rgb(0 0 0 / 7%);
  border: 1px solid #dbdeeb;
  border-radius: 6px;
  background: $white;
  word-break: break-all;
  font-weight: 600;
  text-align: left;
  z-index: 10;
}

.accountInputParsingRecordsTop {
  top: unset;
  bottom: 64px;
}

.accountInputParsingRecordsList {
  padding: 0 12px;
  max-height: 200px;
  overflow-y: scroll;
}

.accountInputParsingRecordsLabel {
  padding: 0 12px;
  display: inline-block;
  color: #717391;
  line-height: 20px;
}

.accountInputParsingRecordsItem {
  margin-top: 8px;
  padding: 8px;
  display: flex;
  border-radius: 4px;
  background: #e9ebf8;
  cursor: pointer;

  &:hover {
    background: rgba(233, 235, 248, 0.5);
  }
}

.accountInputParsingRecordsMarginRight8 {
  margin-right: 8px;
}

.accountInputParsingRecordsWidth24 {
  display: inline-block;
  min-width: 24px;
}

.accountInputParsingRecordsAddress {
  color: $primary-font-color;
  line-height: 20px;
}

.accountInputParsingRecordsTag {
  display: inline-block;
  margin-top: 4px;
  padding: 1px 4px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6e9f7;
  color: #2471fe;
  line-height: 20px;
}

.accountInputParsingRecordsArrowIcon {
  position: absolute;
  top: -10px;
  right: 8px;
  display: flex;
  width: 36px;
}

.accountInputParsingRecordsArrowIconTop {
  top: unset;
  bottom: -10px;
  transform: rotate(180deg);
}

@keyframes rotate360DegAnimation {
  100% {
    transform: rotate(360deg);
  }
}


.accountInputParsingRecordsLoadingIcon {
  svg {
    animation: rotate360DegAnimation 0.9s linear infinite;
  }
}
