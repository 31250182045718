@import "../variables";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 700;
  color: $primary-font-color;
  border-radius: 60px;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  background-color: #52627919;

  &:disabled {
    cursor: no-drop;
    color: rgba(0, 0, 0, 0.2);
    border: 1px solid #d2d9e1;
    box-shadow: none !important;
  }

  &:hover {
    background-color: #52627933;
  }

  .button__link {
    padding: 17px 14px;
    color: unset;
  }
}

.button__block {
  width: 100%;
}

.button__type__primary {
  border: 0;
  color: $white;
  background-color: #22c493;

  &:disabled {
    border: 0;
    cursor: no-drop;
    color: #1eae82;
    background-color: #d6efe7;
    box-shadow: none !important;
    justify-content: start;
    font-size: 16px;
    &:hover {
      background-color: #d6efe7;
    }
  }

  &:hover {
    background-color: #1eae82;
  }
}

.button__loading {
  border: 0;
  background-color: #d6efe7;
  color: #1eae82;

  &:disabled {
    box-shadow: none !important;
  }
  &:hover {
    background-color: #d6efe7;
  }
}

.button__loading_no_gradient {
  border: 0;

  &:disabled {
    background-color: #22c493;
    opacity: 0.6;
    color: $white;
    justify-content: center;

    box-shadow: none !important;
    &:hover {
      background-color: #1eae82;
    }
  }

}

.button__default_loading {
  border: 0;
  color: rgba(0, 0, 0, 0.2);
  cursor: no-drop;
  background-color: #5262791a;
  box-shadow: none !important;
  justify-content: start;
  &:disabled {
    &:hover {
      background-color: #5262791a;
    }
  }
  &:hover {
    background-color: #5262791a;
  }
}

.button__done {
  border: 0;
  color: $white;
  background-color: #22c493;
  border: 0;
  color: #1eae82;
  background-color: #d6efe7;
  box-shadow: none !important;
  font-size: 16px;
  &:hover {
    background-color: #d6efe7;
  }
}

.button__type__solid {
  padding: 0 12px;
  border: 2px solid #000000;

  &:disabled {
    cursor: no-drop;
    color: rgba(0, 0, 0, 0.2);
    border: 2px solid #d2d9e1;
    box-shadow: none !important;
  }

  &:hover {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.2);
  }
}

.button__type__link {
  padding: 0;
}

.button__size__small {
  padding-left: 12px;
  padding-right: 12px;
  height: 30px;

  .button__link {
    padding: 7px 14px;
    color: unset;
  }
}

.button__size__middle {
  height: 38px;

  .button__link {
    padding: 11px 14px;
    color: unset;
  }
}

.button__size__large {
  height: 52px;
  font-size: 16px;
  border-radius: 60px;

  .button__link {
    padding: 21px 14px;
    color: unset;
  }
}

.button__size__large.button__default_loading {
  border: 0;
  color: white;
  cursor: no-drop;
  background-color: #22c493;
  box-shadow: none !important;
  justify-content: center;

  &:disabled {
    &:hover {
      background-color: #22c493;
    }
  }
  &:hover {
    background-color: #22c493;
  }
}

.button__type__black {
  border: 0;
  color: $white;
  background-color: #31353F;

  &:disabled {
    border: 0;
    cursor: no-drop;
    color: $white;
    background-color: #31353FB3 !important;
    box-shadow: none !important;
    font-size: 16px;
  }

  &:hover {
    background-color: #31353FB3 !important;
  }
}

.button__status__default {
  color: $primary-font-color;
  background-color: $normal-color;

  &:disabled {
    cursor: no-drop;
    color: rgba(17, 20, 45, 0.3);
  }

  &:hover {
    background-color: $normal-hover-color;
  }
}

.button__status__primary {
  color: $white;
  background-color: #226CF2;

  &:disabled {
    cursor: no-drop;
    color: $white;
    opacity: 0.2;
  }

  &:hover {
    background-color: #226CF2;
  }
}

.button__status__normal {
  color: #20232D;
  background-color: #F3F5F7;
  border: 1px solid #ECF1F3;

  &:disabled {
    cursor: no-drop;
    background-color: #F3F5F7;
    opacity: 70%;
    color: #20232D;
  }

  &:hover {
    background-color: #F3F5F7 !important;
    opacity: 70%;
  }
}

.button__status__success {
  color: $white;
  background-color: $success-color;

  &:disabled {
    cursor: no-drop;
    opacity: 0.6;
  }

  &:hover {
    background-color: $success-hover-color;
  }
}

.button__status__error {
  color: $white;
  background-color: $error-color;

  &:disabled {
    cursor: no-drop;
    color: $white;
    background-color: $disabled-color !important;
  }

  &:hover {
    background-color: $error-hover-color;
  }
}

@keyframes rotate360DegAnimation {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  animation: rotate360DegAnimation 0.9s linear infinite;
}

.button__loadingIcon {
  svg {
    animation: rotate360DegAnimation 0.9s linear infinite;
  }
}

.button__loadingIcon__marginRight8 {
  margin-right: 12px;
}
