@import '@did/uikit/src/variables';


.textInput {
  display: flex;
  flex-direction: column;
}

.textInput__container {
  display: flex;
  position: relative;
}

.textInput__suffix {
  display: inline-block;
  position: absolute;
  right: 24px;
  top: 19px;
}

.textInput__input {
  flex: 1;
  height: 34px;
  box-sizing: content-box;
  padding: 10px 24px 10px 24px;
  border-radius: 12px;
  border: 1px solid $normal-color;
  background: $normal-color;
  color: $primary-font-color;
  outline: none;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  -webkit-appearance: none;

  &:focus{
    background: $white;
    border-color: #22C493;
  }


  &:hover {
    border: 1px solid #22C493;
    background: $white;
  }
}

.textInput__input__suffix {
  padding-right: 72px;
}

.textInput__input_error {
  border: 1px solid $error-color;
  background: $white;

  &:hover {
    border: 1px solid $error-color;
  }
}

.textInput__errorMessages {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 600;
  color: $error-font-color;
  line-height: 14px;
  text-align: left;
}
