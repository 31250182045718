@import '@did/uikit/src/variables';

.action-label {
  padding: 0 6px;
  display: inline-block;
  width: 64px;
  height: 24px;
  background: $white;
  text-align: center;
  line-height: 24px;
  font-size: $font-size-12;
  font-weight: 500;
  color: $primary-font-color;
  border-radius: 15px;
}

.action-label_change,
.action-label_add {
  color: $success-font-color;
  border: 0;
}

.action-label_delete {
  color: $error-font-color;
  border: 0;
}
