@import '@did/uikit/src/variables';

.renewal-bottom-sheet__renewal-period {
  box-sizing: content-box;
  display: block;
  width: 200px;
  border: 0;
  border-bottom: 1px solid #B6C4D966 !important;
  border-radius: 0;
  text-align: center;
  font-size: 64px;
  font-weight: 600;
  font-family: $barlow-medium;
  line-height: normal;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.renewal-bottom-sheet__renewal-period__error {
  margin-top: 6px;
  text-align: center;
  color: $error-font-color;
}

.renewal-bottom-sheet__renewal-label {
  margin-top: 48px;
  text-align: center;
  line-height: 19px;
  word-break: break-word;
  hyphens: auto;
}

.renewal-bottom-sheet__account_sub-account {
  color: #E4B169;
}

.renewal-bottom-sheet__confirm-renewal__paid-amount {
  margin-top: 32px;
  text-align: center;
  font-size: 32px;
  font-family: $barlow-medium;
  font-weight: 500;
  color: $primary-font-color;
  line-height: 38px;
}

.renewal-bottom-sheet__confirm-renewal__symbol {
  color: $third-font-color;
}

.renewal-bottom-sheet__payment-error {
  font-size: $font-size-14;
  font-weight: 500;
  color: $error-font-color;
  text-align: center;
  padding: 10px 0;
}

.renewal-bottom-sheet__warning {
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  margin-bottom: 18px;
  color: $error-font-color;
}

.renewal-bottom-sheet_with-fiat {
  margin-top: 24px;
}

.renewal-bottom-sheet_with-fiat_terms {
  display: flex;
  margin-top: 16px;
}

.renewal-bottom-sheet_with-fiat_terms-link {
  font-weight: 500;
  line-height: normal;
  color: $link-font-color;

  &:hover {
    color: $link-hover-font-color
  }
}

.renewal-bottom-sheet_blank {
  margin-left: 4px;
}

.renewal-bottom-sheet_with-fiat_service-fee {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: $assist-font-color;
}

.renewal-bottom-sheet_with-fiat_total {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
  color: $primary-font-color;
  line-height: 28px;
}
