@import '../variables';

.langSwitcher {
  position: relative;
  display: inline-block;
  text-align: left;
  font-weight: 600;

  .langSwitcher__item {
    padding: 10px 11px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: $normal-color;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background: $normal-hover-color;
    }
  }

  .langSwitcher__list {
    position: absolute;
    right: 0;
    z-index: 10;
    padding: 12px;
    background: $white;
    box-shadow: $option-outer-box-shadow;
    border-radius: 12px;
    border: $container-border;
    margin-top: 4px;
  }

  .langSwitcher__language__icon {
    margin-right: 6px;
  }

  .langSwitcher__language__arrowDown {
    margin-left: 12px;
    transform: rotate(90deg);
  }

  .langSwitcher__list__item {
    display: flex;
    padding: 12px;
    cursor: pointer;
    white-space: nowrap;
    font-size: $font-size-16;
    font-weight: 500;
    color: $primary-font-color;

    &:hover {
      border-radius: 8px;
      background: $normal-color;
    }
  }

  .langSwitcher__list__item__icon {
    margin-right: 8px;
  }
}