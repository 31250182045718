@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'add-to-calendar-button/assets/css/atcb.css';

@layer base {
  h3 {
    @apply font-bold;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@font-face {
  font-family: "Barlow-Medium";
  src: url('https://das-assets.d.id/fonts/Barlow-Medium.ttf');
}

@font-face {
  font-family: "RubikMonoOne-Regular";
  src: url('../../public/fonts/RubikMonoOne-Regular.ttf');
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

html {
  color: #11142d;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu,
  Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB,
  Microsoft YaHei UI, Microsoft YaHei, Source Han Sans CN, sans-serif;
  line-height: 1.15;
  background-color: #f9fbfc;
}

:focus {
  @apply outline-0;
}

:focus-visible {
  @apply outline-0;
}

/* Hide scrollbars across all browsers */
.hide-scrollbar {
  overflow: -moz-scrollbars-none; /* Firefox */
  -ms-overflow-style: none;       /* Internet Explorer and Edge */
  scrollbar-width: none;          /* Firefox */
}

/* Hide scrollbars for Webkit browsers */
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/*overlay styles*/
/*add-to-calendar-button*/
#atcb-bgoverlay {
  cursor: auto !important;
  background: rgba(27, 29, 33, 0.7);
  backdrop-filter: unset;

  .atcb-list.atcb-modal {
    box-shadow: unset;
    border-radius: 6px;
    overflow: hidden;
  }

  #atcb-btn-custom-close {
    display: none;
  }
}
