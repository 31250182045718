@import '@did/uikit/src/variables';

.manage-permission-bottom-sheet__permissions {
  margin-top: 32px;
}

.manage-permission-bottom-sheet__permissions__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
}

.manage-permission-bottom-sheet__owner {
  margin-bottom: 16px;
  background: $success-color;

  &:hover {
    opacity: 0.7;
  }
}

.manage-permission-bottom-sheet__manager {
  background: #5576F9;

  &:hover {
    opacity: 0.7;
  }
}

.manage-permission-bottom-sheet__permissions__title {
  font-size: $font-size-18;
  font-weight: 500;
  color: $white;
  line-height: 21px;
}

.manage-permission-bottom-sheet__permissions__desc {
  margin-right: 8px;
  font-size: $font-size-14;
  font-weight: 400;
  color: $white;
  line-height: 16px;
  margin-top: 4px;
  display: inline-block;
  opacity: 0.7;
}

.manage-permission-bottom-sheet__label {
  display: block;
  margin-bottom: 4px;
  color: #121314;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
}

.manage-permission-bottom-sheet__link {
  display: inline;
  word-break: break-word;
  line-height: 17px;
  color: $link-font-color;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    color: $link-hover-font-color;
  }
}

.manage-permission-bottom-sheet__margin-top-32 {
  margin-top: 32px;
}

.manage-permission-bottom-sheet__margin-bottom-20 {
  margin-bottom: 20px;
}

.manage-permission-bottom-sheet__confirm {
  margin: 32px 0;
  padding: 12px;
  border-radius: 12px;
  background: #EDF0F2;
  border: $container-border;
}

.manage-permission-bottom-sheet__second-confirmation {
  margin-bottom: 32px;
}

.manage-permission-bottom-sheet__tips {
  margin-top: 8px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 2px;
  grid-template-columns: 7px auto;
  font-size: $font-size-14;
  font-weight: 400;
  color: $assist-font-color;
  line-height: 17px;
  word-break: break-word;
  hyphens: auto;
}

.manage-permission-bottom-sheet__tips__link {
  color: $link-font-color;

  &:hover {
    color: $link-hover-font-color;
  }
}
