@import '@did/uikit/src/variables';

.select {
  position: relative;
}

.select__input__container {
  position: relative;
  display: flex;
  align-items: center;
}

.select__input {
  box-sizing: content-box;
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 11px 48px 11px 24px;
  border-radius: 12px;
  border: 1px solid #F7F7F7;
  background: #F7F7F7;
  color: #121314;
  caret-color: #22C493;
  outline: none;
  font-size: $font-size-14;
  font-weight: 500;

  &:hover {
    border: 1px solid #22C493;
  }

  &:focus {
    border: 1px solid #22C493;
    background: #FFFFFF;
  }

  &::placeholder {
    font-weight: 500;
    color: $third-font-color;
  }
}

.select__input__readonly {
  cursor: pointer;
}

.select__input__error {
  border: 1px solid $error-color !important;
  background: #FFFFFF !important;
}

.select__input__arrow-down {
  position: absolute;
  right: 16px;
}

.select__input__error-messages {
  margin-top: 4px;
  font-size: $font-size-12;
  font-weight: 500;
  color: $error-font-color;
  line-height: 14px;
}

.select-options-enter-active {
  animation: fadeInUp 0.1s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}

.select-options-leave-active {
  animation: fadeOutDown 0.1s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}

.select__options {
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  display: block;
  margin: 8px auto 0 auto;
  padding: 12px;
  max-height: 200px;
  background: $white;
  box-shadow: $option-outer-box-shadow;
  border-radius: 12px;
  border: $container-border;
  z-index: 2;
  overflow: scroll;
}

.select__options__item {
  box-sizing: content-box;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  grid-template-columns: 20px auto;
  padding: 12px;
  height: 20px;
  cursor: pointer;
  font-size: $font-size-16;
  font-weight: 500;
  color: $primary-font-color;

  &:hover {
    background: $normal-color;
    border-radius: 8px;
  }
}
