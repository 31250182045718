$stripe-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

.stripe-input {
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
  height: 44px;
  padding: 12px;
  border-radius: 5px;
  transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease,
    color 0.15s ease;
  border: 1px solid #e6e6e6;
  &:focus {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02),
      0 0 0 3px rgba(5, 115, 225, 0.25), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(5, 115, 225, 0.5);
  }
  &:focus-visible {
    outline: none;
  }
  &::placeholder {
    font-weight: 300;
    color: #757680;
  }
}

.stripe-input-error {
  color: #df1b41;
  border-color: #df1b41;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 1px #df1b41;
}

.stripe-input-error-msg {
  margin-top: 0.25rem;
  color: #df1b41;
  font-size: 0.93rem;
}

.stripe-input-label {
  margin-bottom: 0.25rem;
  font-size: 0.93rem;
  font-weight: 400;
  font-family: $stripe-font-family;
  color: #30313d;
}
