// font
@font-face {
  font-family: "Barlow-Medium";
  src: url('https://das-assets.d.id/fonts/Barlow-Medium.ttf');
}
$body-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, /* english */
"PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", "Source Han Sans CN", sans-serif;	/* chinese */
$barlow-medium: "Barlow-Medium";
$monospace-font-family: Courier, FreeMono, monospace;

// layout breakpoint
$screen_sm: 540px; // secondary
$screen_md: 768px; // primary
$screen_lg: 1024px;
$screen_xl: 1200px; // primary

// main color
$white: #FFFFFF;
$background: #fff;
$primary-color: #FF5E8E;
$primary-hover-color: #586CDD;
$normal-color: #F7F7F7;
$normal-hover-color: #EBEBEB;
$success-color: #22C493;
$success-hover-color: #10A376;
$error-color: #DF4B46;
$error-hover-color: #B83E39;
$disabled-color: #A0A1AB;
$assist-color: #11142D;
$focus-color: #2D64F6;
$container-border-color: #DAE2F7;

// font color
$primary-font-color: #11142d;
$assist-font-color: #636D85;
$third-font-color: #9BA0A7;
$success-font-color: #22C493;
$link-font-color: #3D66B3;
$link-hover-font-color: rgba(61, 102, 179, 0.7);
$warn-font-color: #FFA800;
$error-font-color: #DF4A46;

// font size
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-24: 24px;
$font-size-36: 36px;

// layout
$pc-layout-container-width: 532px;
$pc-layout-container-min-height: calc(100vh - 134px);
$pc-layout-container-box-shadow: 0 6px 8px 0 rgb(223 231 227 / 86%);

// nav
$bottom-nav-height: 48px;

// container
$container-outer-box-shadow: 0 20px 16px 0 rgb(43 54 77 / 16%);
$container-border: 1px solid $container-border-color;
$option-outer-box-shadow: 0px 8px 10px 0 rgba(0, 0, 0, 0.05);

// split line
$split-line: 1px $container-border-color dashed;

// divider
$divider-color: rgba(186, 200, 209, 0.24);
