@import '@did/uikit/src/variables';

.warning-alert {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  padding: 8px;
  background: #FCECEC;
  border-radius: 8px;
  font-size: $font-size-14;
  font-weight: 400;
  color: $error-font-color;
  line-height: 14px;
  word-break: break-word;
  hyphens: auto;
}
