@import '@did/uikit/src/variables';

.record-details_item {
  margin-bottom: 32px;
}

.record-details_item_label {
  margin-bottom: 8px;
  font-size: $font-size-14;
  font-weight: 800;
  color: $primary-font-color;
  line-height: 16px;
  text-transform: capitalize;
  word-break: break-word;
  hyphens: auto;
}

.record-details_value {
  word-wrap: break-word;
  hyphens: auto;
  font-size: $font-size-14;
  font-weight: 500;
  color: #31333e;
  line-height: 16px;
}

.record-details_margin-bottom-0 {
  margin-bottom: 0;
}
