@import '@did/uikit/src/variables';

.confirm-changes-item {
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 50px;
  background: #EDF0F2;
  border-radius: 25px;
}

.confirm-changes__item__header {
  display: inline-flex;
  align-items: center;
}

.confirm-changes_item_info_logo {
  margin-right: 8px;
}

._disable {
  filter: grayscale(100%);
}

.confirm-changes__item__label {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $font-size-16;
  font-weight: bold;
  color: $primary-font-color;
}

.confirm-changes__item__value {
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: $font-size-14;
  font-weight: 500;
  color: $assist-font-color;
}

.confirm-changes__item_delete {
  color: $error-color;
  text-decoration: line-through;
}
