@import '../variables';

.toast {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 16px;
  width: max-content;
  height: max-content;
  max-width: 300px;
  min-width: 120px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: $white;
  line-height: 22px;
  box-shadow: $container-outer-box-shadow;
  background: $assist-color;
  z-index: 30;
}
