@import '@did/uikit/src/variables';

.confirm-changes__container {
  height: 370px;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
}

.confirm-changes__group {
  margin-bottom: 8px;
  font-size: $font-size-14;
  font-weight: 500;
  color: $primary-font-color;
}

.confirm-changes__margin-top-14 {
  margin-top: 14px;
}
