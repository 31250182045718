@import '@did/uikit/src/variables';

.parsing-records_container {
  background: $white;
  box-shadow: $container-outer-box-shadow;
  border-radius: 24px;
  border: $container-border;
  overflow: hidden;

  &.dwebs {
    grid-column-start: span 2;
  }
}

.parse-record_container {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  min-height: 280px;
  max-height: 454px;
  padding: 16px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
}

.parse-record_container_dweb {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 50px);
  grid-column-gap: 32px;
}

.parsing-records_item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 50px;
  padding: 9px 10px;
  background: #EDF0F2;
  cursor: pointer;

  &:hover {
    background: rgba(237, 240, 242, 0.7);
  }
}

.parsing-records_item_info {
  display: inline-flex;
  align-items: center;
}

.parsing-records_item_info_logo {
  margin-right: 8px;
}

.parsing-records_account-info_label {
  display: inline-flex;
  align-items: center;
  font-size: $font-size-16;
  font-weight: bold;
  color: $primary-font-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1;
}

.parsing-records_account-info_address {
  font-size: $font-size-14;
  font-weight: 500;
  color: $assist-font-color;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.monospace {
  font-family: $monospace-font-family;
}

.parsing-records_item_tag {
  display: inline-flex;
  margin-left: 4px;
  padding: 0 2px;
  background: rgba(82, 98, 121, 0.10);
  border-radius: 4px;
  font-size: $font-size-12;
  font-weight: 500;
  color: #5F6570;
  line-height: 16px;
}

.parse-record_bottom-tips {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 30px 0 rgba(0, 0, 0, 0.06);
}

.parse-record_bottom-tips_container {
  box-sizing: content-box;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parse-record_top-tips_exit {
  width: 150px;
}

.parse-record_bottom-tips_button {
  width: 150px;
}

.parse-record_bottom-tips_item {
  font-size: 20px;
  color: #20232D;
  padding: 0 14px;
  font-weight: 500;
}

.parse-record_bottom-tips_item_value {
  color: $success-font-color;
  font-weight: 500;
}

.parsing-records_task-not-completed_item {
  margin-top: 8px;
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 2px;
  grid-template-columns: 14px auto;
  font-weight: 500;
}

.parsing-records_task-not-completed_faq {
  display: block;
  margin-top: 10px;
  font-weight: 500;
  line-height: 20px;
  color: $link-font-color;

  &:hover {
    color: $link-hover-font-color;
  }
}

.parsing-records_no-item {
  display: inline-block;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: max-content;
  height: max-content;
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

.parse-record-bottom-tips-enter-active {
  animation: slideInUp 0.2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}

.parse-record-bottom-tips-leave-active {
  animation: slideOutDown 0.2s cubic-bezier(0.75, 0.25, 0.25, 0.75);
}

.add-new-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-height: 48px;
  background: $white;
  border: 1px dashed $primary-font-color;
  font-size: $font-size-16;
  font-weight: 500;
  color: #2471FE;
  cursor: pointer;

  &:hover {
    background: rgba(237, 240, 242, 0.6);
  }
}
