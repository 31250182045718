@import '@did/uikit/src/variables';

.renewal-submitted__content {
  font-size: $font-size-14;
  color: $primary-font-color;
  line-height: 20px;
  word-break: break-word;
}

.renewal-submitted__action {
  height: 52px;
  background: #D6EFE7;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-14;
  font-weight: 600;
  color: $success-font-color;
  cursor: pointer;
}

.renewal-submitted__tx-hash {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: $font-size-12;
  font-weight: 400;
  color: $third-font-color;
  line-height: 14px;
  margin-top: 10px;
}

.renewal-submitted__tx-hash__icon {
  margin-left: -2px;
}
