@import '@did/uikit/src/variables';

.action-buttons {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 10px;
  background: $white;
  border-radius: 16px;

  .action-buttons_button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 32px;
    background: #FFFFFF;
    opacity: 1;
    outline: 0;
    border: 0;
    border-radius: 15px 0 0 15px;
    cursor: pointer;

    &:hover {
      background: $normal-color;
    }

    &._right {
      border-radius: 0 15px 15px 0;
    }
  }

  .action-buttons_split-line {
    display: inline-block;
    width: 1px;
    height: 12px;
    background: #EAECED;
  }
}
