@import '@did/uikit/src/variables';

.modify-parsing-record {
  display: flex;
  flex-direction: column;
}

.modify-parsing-record__label {
  display: block;
  font-size: $font-size-14;
  font-weight: 500;
  color: $primary-font-color;
  line-height: 16px;
  margin-bottom: 4px;
}

.modify-parsing-record__label__tip {
  font-size: $font-size-14;
  font-weight: 400;
  color: $third-font-color;
  line-height: 16px;
}

.modify-parsing-record__button {
  margin-top: 32px;
}

.modify-parsing-record__margin-top-32 {
  margin-top: 32px;
}
