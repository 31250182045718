@import "../variables";

.container {
  position: relative;
  margin: 0 auto;
  height: 62px;
  max-width: $screen_xl;
  z-index: 0;
}

.logoLink {
  display: inline-flex;
  font-size: 26px;
  font-weight: bold;
  align-items: center;
  color: $primary-font-color;
}

// .logo {
//   height: 23px;
//   width: 140px;
// }

.rightActions {
  float: right;
  display: flex;
  align-items: center;
}

.langSwitcher {
  margin-right: 12px;
}

.menus {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  z-index: -1;
}

.item {
  margin: 0 13px;
  padding: 0 12px;
  display: inline-block;
  height: 39px;
  border-radius: 8px;
  line-height: 39px;
  font-size: $font-size-14;
  font-weight: 600;
  color: $primary-font-color;

  &:hover {
    background: rgba(7, 58, 141, 0.08);
  }
}

.topNavMobile {
  justify-content: space-between;
  align-items: center;

  &__logoLink {
    margin-left: 16px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: $primary-font-color;
  }

  &__logo {
    height: 23px;
  }

  &__langSwitcher {
    margin-right: 16px;
  }
}
